import { Box, Container, Text, VStack } from '@chakra-ui/layout'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal'
import { StaticImage } from 'gatsby-plugin-image'
import { round } from 'lodash'
import React, { useMemo, useState } from 'react'
import Layout from '../../../containers/Layout'

const canvasSize = [750, 977]

const people = [
  {
    id: 'family',
    name: '家屬',
    img: <StaticImage src="family.png" alt="家屬" />,
    style: { top: 517, left: 72, width: 145, height: 373 },
    content: `**直到現在，我還是沒有收拾他的房間⋯⋯**
    **我不知道該怎麼原諒酒駕的人，但就算判死刑⋯⋯也換不回我的孩子⋯⋯**

    那天清晨我接到電話，趕到醫院的路上眼淚根本停不下來。常看到酒駕的新聞，但從沒想過這樣的事會發生在我們身上，我的孩子人生才剛要開始，竟然就這樣沒了。
    諷刺的是，酒駕的人跟警察說他酒都退了只是想要回家，但卻害我的孩子永遠都回不了家⋯⋯

    但說真的⋯⋯罰再重可能還是阻止不了像他這種心存僥倖的人⋯⋯
    現在酒駕的人裡面將近一半都是累犯。也就是說，加重刑罰可能對一些人有嚇阻作用，但如果真的罰就會怕，那怎麼還會有人累犯？
    [chart]

    我是覺得要預防這種僥倖的心態，需要一些更根本的措施
    比如有酒駕記錄的人車子都應該要加裝酒精鎖，發動前酒測通過才可以開車。或是酒駕同車的乘客也應該連帶處罰，或甚至根本不該賣酒給這些人⋯⋯等事情發生，雖然我心中充滿恨，但早就已經來不及了⋯⋯
    `,
    chart: <StaticImage src="3.png" alt="圖表3" />,
  },
  {
    id: 'driver',
    name: '肇事駕駛',
    img: <StaticImage src="driver.png" alt="肇事駕駛" />,
    style: { top: 416, left: 302, width: 150, height: 300 },
    content: `嗯⋯⋯我真的真的感到很抱歉⋯⋯

    我的工作非常需要和客戶應酬喝酒，我也知道酒後不該開車⋯⋯但我住的地方實在很偏遠，過了21:00就沒有公車，很多計程車司機也因為太遠而拒載。

    其實這已經不是我第一次因為酒駕面臨刑責，根據法律規定，如果是累犯的話需要服滿2/3的刑期才能聲請假釋，而且就算有假釋的資格，也只有大約1/3的人真的獲得假釋。

    我也真的很努力想要戒酒，但在監獄的環境中其實很難做到。比起進監獄，或許戒癮治療對我這種對酒精產生依賴的人會更有幫助。
    `,
    chart: <StaticImage src="1.png" alt="圖表1" />,
  },
  {
    id: 'da',
    name: '檢察官',
    img: <StaticImage src="da.png" alt="檢察官" />,
    style: { top: 518, left: 544, width: 157, height: 374 },
    content: `嗨！看來你對酒駕這個議題滿有興趣，我可以跟你聊聊我這些年對酒駕者的一些觀察，希望對你會有幫助！

    首先，先說一下酒駕的定義
    警察臨檢酒測時，若濃度高於0.25mg/L就會被以刑法「不能安全駕駛罪」移送法辦；但如果濃度介於0.15-0.25mg/L之間，雖然不會移送法辦，但還是會處以罰鍰。

    雖然「不能安全駕駛罪」最高可以判2年，但大多數的酒駕都是**被臨檢但沒有肇事**，通常判決會是6個月以下，且大部分都會易科罰金。但像這個案件有撞死人，根據刑法就會判3-10年有期徒刑。

    [chart]
    從數據來看，2013年酒駕移送法辦的酒測值從0.55mg/L改為0.25mg/L後，可以發現酒駕的案件數就開始呈現下降的趨勢。但這都只是「有出事」或「有被臨檢到」的件數，實際上還有更多黑數。

    所以，我認為除了加強取締之外，真正要預防酒駕，可能還是要在喝醉的人開車或騎車之前就阻止，像是規範特定場所超過一定時間後就不賣酒、在停車場加設酒精鎖等等。
    `,
    chart: <StaticImage src="2.png" alt="圖表2" />,
  },
]

const MiniMd = ({ content = '', chart }) => {
  return useMemo(() => {
    const lines = content.split('\n').map((l, i) => {
      const line = l.trim()
      if (line === '[chart]') return <div key={i}>{chart}</div>
      const items = []
      const pttn = /\*\*([^*]+)\*\*/g
      let lastIndex = 0
      let res
      while (res = pttn.exec(line)) {
        if (res.index > 0) {
          items.push(line.substring(lastIndex, res.index))
        }
        items.push(<Text key={res.index} as="span" fontWeight="bold">{res[1]}</Text>)
        lastIndex = res.index + res[0].length
      }
      items.push(line.substring(lastIndex))
      return <Text key={i}>{items}</Text>
    })
    return (
      <VStack alignItems="flex-start">
        {lines}
      </VStack>
    )
  }, [content, chart])
}

const peopleData = people.reduce((obj, p) => {
  obj[p.id] = p
  return obj
}, {})

const p = n => `${round(n * 100, 2)}%`

const getStyle = style => ({
  left: p(style.left / canvasSize[0]),
  width: p(style.width / canvasSize[0]),
  top: p(style.top / canvasSize[1]),
})

const Edu = () => {
  const [openId, setOpen] = useState()

  return (
    <Layout bg="#192D61">
      <Container maxW="container.sm">
        <Box position="relative">
          <StaticImage src="bg.png" alt="酒駕車禍場景" />
          {people.map(p => (
            <Box key={p.id} position="absolute" style={getStyle(p.style)} cursor="pointer" onClick={() => setOpen(p.id)}>
              {p.img}
            </Box>
          ))}
        </Box>
      </Container>
      <Modal isOpen={Boolean(openId)} onClose={() => setOpen(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{peopleData[openId]?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MiniMd {...peopleData[openId]} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default Edu
